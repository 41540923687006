<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
        :title="$t('page.founder.title')"
        @edit="$emit('edit')"
    >
      <p class="has-text-justified">{{ $t('page.founder.description') }}</p>
    </page-title>
    <div class="columns">
      <div class="column is-half">
        <b-field
            :label="getFieldLabel('firstname')"
            :type="getFieldType('firstname')"
            :message="getFieldMessage('firstname')"
        >
          <b-input
              v-if="isFieldEditable('$.founder.firstname')"
              v-model="model.firstname"
              @blur="validateFirstname"
              custom-class="page-focus-first"
              name="fname"
              autocomplete="given-name"
          >
          </b-input>
          <span v-else>{{ model.firstname }}</span>
        </b-field>
        <revision-message path="$.founder.firstname"></revision-message>
      </div>
      <div class="column is-half">
        <b-field
            :label="getFieldLabel('lastname')"
            :type="getFieldType('lastname')"
            :message="getFieldMessage('lastname')"
        >
          <b-input
              v-if="isFieldEditable('$.founder.lastname')"
              v-model="model.lastname"
              @blur="validateLastname"
              name="lname"
              autocomplete="family-name"
          >
          </b-input>
          <span v-else>{{ model.lastname }}</span>
        </b-field>
        <revision-message path="$.founder.lastname"></revision-message>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <b-field
            :label="getFieldLabel('phone')"
            :type="getFieldType('phone')"
            :message="getFieldMessage('phone')"
        >
          <b-input
              v-if="isFieldEditable('$.founder.phone')"
              v-model="model.phone"
              type="tel"
              @blur="validatePhone"
              name="phone"
              autocomplete="tel"
          >
          </b-input>
          <span v-else>{{ model.phone }}</span>
        </b-field>
        <revision-message path="$.founder.phone"></revision-message>
      </div>
      <div class="column is-half">
        <b-field
            :label="getFieldLabel('birthdate')"
            :type="getFieldType('birthdate')"
            :message="getFieldMessage('birthdate')"
        >
          <b-datepicker
              v-if="isFieldEditable('$.founder.birthdate')"
              v-model="model.birthdate"
              :max-date="atLeastEighteenYearsOld"
              :date-formatter="formatDateWithSlashes"
              icon="calendar-day"
              trap-focus
              @blur="validateBirthdate"
          >
          </b-datepicker>
          <span v-else>{{ formatBirthdate(model.birthdate) }}</span>
        </b-field>
        <revision-message path="$.founder.birthdate"></revision-message>
      </div>
    </div>
    <section-address
        ref="founderAddress"
        :model="model"
        parent-path="$.founder"
    >
    </section-address>
    <div class="columns">
      <div class="column is-two-thirds">
        <b-field
            :label="getFieldLabel('email')"
            :type="getFieldType('email')"
            :message="getFieldMessage('email')"
        >
          <b-input
              v-if="isFieldEditable('$.founder.email')"
              v-model="model.email"
              type="email"
              @blur="validateEmail"
              name="email"
              autocomplete="email"
          >
          </b-input>
          <span v-else>{{ model.email }}</span>
        </b-field>
        <revision-message path="$.founder.email"></revision-message>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import date from '@/mixins/date'
  import page from '@/mixins/page'
  import SectionAddress from './SectionAddress'

  export default {
    name: 'PageFounder',
    mixins: [
      date,
      page('founder')
    ],
    components: {
      SectionAddress
    },
    methods: {
      validate () {
        this.clearErrors()

        this.validateBirthdate()
        this.validateEmail()
        this.validateFirstname()
        this.validateLastname()
        this.validatePhone()

        const addressErrors = this.$refs.founderAddress.validate()
        if (addressErrors?.length)
          this.addError('founderAddress')
        else
          this.removeError('founderAddress')
      },
      validateBirthdate () {
        if (!this.model.birthdate)
          this.addError('birthdate')
        else
          this.removeError('birthdate')
      },
      validateEmail () {
        if (!this.hasIncorporationAccess) this.validateRegex('email', this.model.email, /^\S+@\S+$/)
      },
      validateFirstname () {
        this.validateNotEmpty('firstname', this.model.firstname)
      },
      validateLastname () {
        this.validateNotEmpty('lastname', this.model.lastname)
      },
      validatePhone () {
        if (!this.hasIncorporationAccess) this.validateMinLength('phone', this.model.phone, 10)
      }
    }
  }
</script>
